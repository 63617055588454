import React from "react";
import { AppLogo } from "../../app-logo";
import { Search } from "../search";
import "./top-bar.m.css";
import { Sidebar } from "../sidebar";
import { DarkModeToggler } from "../dark-mode-toggler";
import { Link } from "@quintype/components";
import { useSelector } from "react-redux";
import { ProfileAvatarWhite } from "../icons";
import { DropdownMenu } from "../drop-down-menu";
import get from "lodash/get";
import { useState } from "react";
import { HomeScreenSubscribeBtn } from "../../../../utils/gtm";

const TopBar = () => {
  const getState = useSelector((state) => state);
  const user = get(getState, ["firebaseUser"], null);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  return (
    <div styleName="main-wrapper">
      <div className="bg-[#ca4242]">
        <div
          className="mx-auto flex relative max-w-7xl items-center justify-between px-3 py-4 mobile:py-2"
          aria-label="Global"
        >
          <div className="flex w-full items-center justify-between mobile:flex-row-reverse">
            <DarkModeToggler />
            <AppLogo />
            <div className="flex items-center justify-end gap-4 mobile:hidden lg:flex-1">
              <Search />
              {user !== null ? (
                <div className="relative">
                  <button
                    type="button"
                    onClick={() => setDropDownOpen(!dropDownOpen)}
                    className="inline-flex items-center gap-x-2 "
                  >
                    <ProfileAvatarWhite />
                  </button>
                </div>
              ) : (
                <div>
                  <Link href={`/signin`}>
                    <ProfileAvatarWhite />
                  </Link>
                </div>
              )}
              {!user && (
                <Link
                  href="https://join.swarajyamag.com/print/?utm_source=homepage&utm_medium=site&utm_campaign=homepagesubsbutton"
                  target={"_blank"}
                >
                  <button
                    className="rounded-full bg-white px-5 py-1 font-satoshi text-xs leading-6 text-black"
                    aria-label="subscribe_btn"
                    onClick={HomeScreenSubscribeBtn}
                  >
                    Subscribe
                  </button>
                </Link>
              )}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
      {dropDownOpen && <DropdownMenu setDropDownOpen={setDropDownOpen} />}
    </div>
  );
};

export { TopBar };
